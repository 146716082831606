<template>
  <v-app>
    <NavigationBar :viewport="viewport"/>
    <v-main>
      <router-view :viewport="viewport"/>
    </v-main>
    <Menu :viewport="viewport"/>
  </v-app>
</template>

<script>

export default {
  name: 'App',
  components: {
  },
  data() {
    return {
    }
  },
  mounted() {
  },
  methods: {
  },
  computed: {
    viewport () {
      return [this.$vuetify.breakpoint.width, this.$vuetify.breakpoint.height]
    }
  }
};
</script>
