import axios from 'axios';

let $http = axios.create({
    baseURL: `${process.env.VUE_APP_API_URL}`,
    headers: {
        'X-Requested-With' : 'XMLHttpRequest',
        'Content-Type': 'application/json; charset="utf-8"'
    },
    withCredentials: false,
    crossDomain: true,
    params: {}
});

const Http = {
    install(Vue) {
        Vue.prototype.$http = $http;
    }
};

export default (Http);
