<template>
  <v-app-bar v-scroll.#main-view="onScroll"
             style="transition: .3s ease-in-out"
             elevation="0"
             color="transparent"
             :height="viewport[1] / 7"
             fixed>
    <template v-slot:default>
      <div class="d-flex justify-space-between navbar">
        <v-sheet color="transparent" width="250">
          <svg v-if="!show" data-logo x="0px" y="0px"
               viewBox="0 0 400 96" style="enable-background:new 0 0 400 96;"
               fill="#1E2C40" xml:space="preserve">
          <g>
            <g>
              <g>
                <rect x="217.64" y="26.44" class="st0" width="8.6" height="42.7"/>
                <path class="st0" d="M190.17,69.56c-12.05,0-21.9-9.05-21.9-21.9c0-12.78,9.85-21.84,21.9-21.84c12.17,0,21.84,9.05,21.84,21.84
                  C212.01,60.51,202.28,69.56,190.17,69.56z M190.17,61.91c7.71,0,13.03-5.57,13.03-14.25s-5.32-14.13-13.03-14.13
                  c-7.77,0-13.09,5.44-13.09,14.13S182.4,61.91,190.17,61.91z"/>
                <path class="st0" d="M256.43,26.44h31.32v6.91h-11.38v35.78h-8.56V33.35h-11.38V26.44z"/>
                <path class="st0" d="M353.48,26.44h8.56v42.7h-8.56V26.44z"/>
                <path class="st0" d="M368.68,26.44H400v6.91h-11.38v35.78h-8.56V33.35h-11.38V26.44z"/>
                <path class="st0" d="M243.02,51.82c6.85-1.83,9.73-7.1,9.73-12.3c0-7.16-5.14-13.09-15.41-13.09h-7.36v7.1h7.05
                  c4.77,0,6.91,2.39,6.91,6.18c0,3.85-2.14,6.36-6.91,6.36h-7.05v6.42h3.93l7.46,13.17c1.21,2.14,3.49,3.47,5.95,3.47h5.92
                  L243.02,51.82z"/>
                <g>
                  <path class="st0" d="M133.76,27.45c-1.08-0.69-2.36-1.08-3.68-1.08h-4.89v42.76h8.56v-29.3l0,0V27.45z"/>
                  <path class="st0" d="M153.8,26.37v29.36l-16.38-24.8v13.45l14.35,21.68c1.27,1.91,3.41,3.06,5.7,3.06h4.89V26.37H153.8z"/>
                </g>
                <g>
                  <polygon class="st0" points="321.95,26.44 321.95,43.99 306.66,43.99 306.66,50.96 321.95,50.96 321.95,69.13 330.51,69.13
                    330.51,26.44 				"/>
                  <rect x="295.1" y="26.44" class="st0" width="8.56" height="42.7"/>
                </g>
              </g>
              <g>
                <path class="st0" d="M27.61,47.88c-1.62,2.7-0.63,6.38-0.63,6.38s4.04-1.08,5.6-3.68c1.64-2.76,0.44-6.22,0.44-6.22
                  S29.11,45.4,27.61,47.88z"/>
                <path class="st0" d="M65.17,52.46c2.02,2.37,6.47,2.7,6.47,2.7s0.01-4.75-2.07-7.01c-2.08-2.26-6.15-2.34-6.15-2.34
                  S63.1,50.04,65.17,52.46z"/>
                <path class="st0" d="M10.63,39.72c-2.78,1.46-3.86,5.12-3.86,5.12s4.01,1.18,6.69-0.23c2.84-1.5,3.61-5.09,3.61-5.09
                  S13.2,38.38,10.63,39.72z"/>
                <path class="st0" d="M74.52,29.24c3.14,0.17,5.93-2.42,5.93-2.42s-2.84-3.07-5.87-3.23c-3.2-0.17-5.71,2.52-5.71,2.52
                  S71.63,29.08,74.52,29.24z"/>
                <path class="st0" d="M47.69,0C21.35,0,0,21.35,0,47.69s21.35,47.69,47.69,47.69c26.34,0,47.69-21.35,47.69-47.69S74.03,0,47.69,0
                  z M79.88,18.71c0.67,0.19,1.13,0.49,1.6,1c3.7,4.47,6.53,9.68,8.24,15.38c0.12,0.75-0.82,1.58-1.7,1.18
                  c-1.16-0.52-2.71-1.08-4.44-1.64c-5.26-1.88-13.11-4.19-22.73-5.54c0.79-0.94,1.63-1.82,2.53-2.6
                  C70.44,20.32,77.42,18.01,79.88,18.71z M65.61,10.11c0.55-0.63,0.93-1.35,1.05-1.99c3.55,1.7,6.83,3.87,9.77,6.42
                  c-3.35,0.84-10.06,3.3-16.52,9.89c-0.33-0.6-1.93-3.49-3.64-6.95c1.08-0.8,2.19-1.61,3.28-2.4
                  C62.09,13.26,64.28,11.64,65.61,10.11z M56.65,28.18c-0.1,0.12-0.2,0.25-0.29,0.37c-3.23-0.33-6.64-0.53-10.18-0.58
                  c-0.11-0.9-0.23-1.78-0.35-2.64c0.89-0.68,3.64-2.78,7.02-5.3C54.67,24.13,56.37,27.62,56.65,28.18z M52.83,4.12
                  c3.06,0.36,6.03,1.03,8.87,1.98c0.61,0.4,0.73,1.41,0.1,1.88c-1.35,1.02-4.31,3.23-7.4,5.53c-0.04-0.09-0.08-0.18-0.12-0.28
                  c-1.79-4.02-2.53-7.18-2.63-7.66C51.45,4.6,52.19,4.1,52.83,4.12z M47.46,3.82c-0.1,0.57-0.11,1.22,0.03,1.87
                  c0.21,0.97,1.81,6.04,3.37,9.79c0.07,0.17,0.14,0.34,0.21,0.51c-2.85,2.12-5.33,3.96-6.14,4.56c-2.2-9.47-3.5-13.38-3.97-14.84
                  c-0.14-0.44-0.36-0.88-0.62-1.27C42.66,4.04,45.04,3.83,47.46,3.82z M41.34,41.87c-1.45-1.65-4.51-4.77-10.41-9.5
                  c3.28-0.52,6.7-0.9,10.15-1.06C41.27,35.01,41.32,38.77,41.34,41.87z M18.52,14.92c0.23,0.51,0.83,1.12,1.48,1.58
                  c2.01,1.4,18.73,9.37,18.73,9.37s-0.39-3.19-0.79-5.42c-1.01-0.66-13.68-7.08-14.36-7.49c-0.64-0.39-0.72-1.28-0.23-1.78
                  c3.45-2.3,7.24-4.13,11.29-5.39c1.47-0.24,2.17,0.92,2.33,1.55c1.08,4.08,2.78,11.7,3.38,15.98c0.21,1.47,0.37,3.07,0.5,4.72
                  c-0.53,0.02-1.07,0.04-1.61,0.07c-4.43,0.22-8.53,0.65-12.26,1.18c-8.41-6.53-9.3-7.29-11.48-8.68
                  c-0.56-0.36-1.15-0.66-1.72-0.77C15.24,18.08,16.82,16.44,18.52,14.92z M9.61,25.9C9.61,25.89,9.61,25.89,9.61,25.9
                  c0.99-1.67,3.26-2.06,4.84-0.64c1.24,1.12,3.31,2.69,6.58,5.03c-7.33,1.41-12.64,3.08-15.1,3.94C6.87,31.3,8.1,28.51,9.61,25.9z
                   M4.57,55.83c0.05,0.02,0.13,0.04,0.13,0.04c1.37,0.33,2.76,0.17,4-0.52c1.31-0.73,22.99-13.23,22.99-13.23l-3.43-3.41
                  c0,0-20.59,11.64-21.48,12.14c-0.92,0.52-2.68,0.05-2.93-1.53c-0.02-0.54-0.03-1.08-0.03-1.63c0-2.24,0.17-4.45,0.49-6.6
                  c0.24-0.94,1.01-1.71,1.88-2.07c1.11-0.47,8.91-3.47,19.06-5.6c2.63,2.13,5.07,4.52,9.12,8.66c4.71,4.81,7.72,6.64,7.94,10.07
                  c0,3.72,0,9.38,0,11.72c0,2.76-1.55,4.26-4.63,5.27c-2.69,0.88-16.23,4.99-22.65,7.84C9.78,71.14,6.08,63.87,4.57,55.83z
                   M16.52,78.56c2.28-0.62,6.84-1.81,12.02-2.91c-1.47,1.07-3.28,2.47-4.95,4c-1.13,1.04-2.11,1.95-2.85,2.65
                  C19.25,81.15,17.84,79.9,16.52,78.56z M25.46,85.53c-1.22-0.72-2.39-1.49-3.53-2.32c1.17-0.9,2.69-2.04,4.32-3.17
                  c0.12-0.09,0.25-0.17,0.37-0.25c-0.02,1.13-0.19,2.75-0.77,4.59C25.72,84.78,25.58,85.19,25.46,85.53z M26.57,86.16
                  c1.6-3.11,2.33-6.17,2.63-8c4.25-2.52,7.82-3.8,9.51-4.32c0.02,0,0.04-0.01,0.05-0.01c-0.95,1.36-2.82,4.15-4.02,6.8
                  c-1.1,2.41-2.48,5.88-3.26,7.85C29.79,87.8,28.15,87.03,26.57,86.16z M32.78,88.97c0.76-1.42,2.17-3.9,4.48-7.38c0,0,0,0,0,0
                  c1.42,2.99,1.99,6.62,2.21,9.22C37.17,90.36,34.93,89.75,32.78,88.97z M40.79,91.03c0.09-3.17-0.02-8.11-1.18-12.57
                  c2.93-3.46,5.62-5.26,5.62-5.26l-0.21-0.01c0.1-0.01,0.2-0.01,0.3-0.02l1.64,18.39C44.86,91.52,42.8,91.34,40.79,91.03z
                   M47.69,44.63c0,0-0.64-7.17-1.17-13.36c2.49,0.08,4.96,0.29,7.37,0.6C48.82,39.18,47.69,44.63,47.69,44.63z M57.81,90.39
                  c-0.19,0.04-0.37,0.09-0.56,0.13c-2.84,0.63-5.78,0.99-8.79,1.04c0.26-2.59,0.62-6.33,0.89-9.69c0,0,0,0,0-0.01
                  c1.06,0.62,2.2,1.48,4.04,3.12c2.1,1.87,3.65,4,4.53,5.38C57.89,90.37,57.85,90.38,57.81,90.39z M59.04,90.08
                  c-0.91-1.81-2.62-4.97-4.74-7.79c-1.94-2.59-3.42-4.21-4.55-5.15c0.14-1.71,0.26-3.05,0.34-3.97c0.05,0,0.11,0.01,0.17,0.01
                  l-0.15,0.07c0,0,4.21,0.54,10.17,3.16c0.23,1.69,0.61,4.2,1.3,7.02c0.48,1.95,1.28,3.9,1.89,5.22
                  C62.02,89.2,60.55,89.68,59.04,90.08z M64.35,88.29c-0.29-1.21-0.66-2.95-0.83-4.57c-0.28-2.66-0.09-4.48,0.11-5.7
                  c3.63,1.88,7.03,3.98,9.39,5.5C70.34,85.42,67.43,87.03,64.35,88.29z M74.25,82.61c-1.91-1.65-4.67-3.84-7.72-5.8
                  c-0.97-0.62-1.93-1.23-2.86-1.8c6.63,1.29,12.68,2.89,15.17,3.58C77.41,80.04,75.88,81.38,74.25,82.61z M91.54,48.9
                  c-0.12,1.1-1.3,2.33-2.85,1.72c-0.87-0.34-13.36-7.4-17.2-9.38c-1.7,0.75-4.63,2.23-4.63,2.23s18.63,10.75,20.25,11.44
                  c1.34,0.57,2.68,0.65,3.81,0.33c-1.43,8.24-5.16,15.69-10.5,21.67c-9.33-4.11-20.09-6.66-23.07-7.86
                  c-3.5-1.41-4.28-2.7-4.28-5.15c0-4.06,0-10.4,0-11.46c0-2.59,1.93-4.05,5.17-6.17c6.06-3.96,14.92-7.94,14.92-7.94
                  s-1.63-0.53-2.83-0.9c-1.17-0.36-2.74-0.8-2.74-0.8s-3.33,1.5-7.02,3.34c-3.75,1.87-7.84,4.54-7.84,4.54s1.89-6.21,5.6-11.98
                  c14.98,2.68,26.9,8.54,26.9,8.54l0.01,0c0.1,0.05,0.21,0.09,0.31,0.14c1.93,0.8,3.94,0.47,5.47-0.51
                  c0.36,2.27,0.55,4.6,0.55,6.97c0,0.35-0.01,0.69-0.01,1.04C91.55,48.78,91.54,48.84,91.54,48.9z"/>
              </g>
            </g>
          </g>
        </svg>
        </v-sheet>
        <v-btn :class="{
          'd-flex justify-center align-center mr-12 hamburger hamburger--emphatic': true,
          'is-active': show
        }"
               @click="showMenu" dark elevation="0" icon>

            <span class="hamburger-box">
              <span data-toggle :class="{'hamburger-inner' : true}"></span>
            </span>
        </v-btn>
      </div>
    </template>
  </v-app-bar>
</template>

<script>
import gsap from 'gsap'
export default {
  name: 'NavigationBar',
  props: {
    viewport: Array
  },
  data () {
    return {
      show: false,
      tl: gsap.timeline(),
      change: false,
    }
  },
  mounted() {
  },
  methods: {
    showMenu () {
      this.show = !this.show
      this.show ? this.animateMenuOpening() : this.animateMenuClosing()
    },
    animateMenuOpening () {
      this.tl.to('#navigation', {
        opacity: 1,
        backgroundColor: '#1E2C40',
        width: this.viewport[0] * 2,
        height: this.viewport[0] * 2,
        x: 0,
        y: 0,
        autoRound: true,
        transformOrigin: "50% 50%",
        zIndex: 2
      })
      document.body.style.position = 'fixed'
    },
    animateMenuClosing () {
      this.tl.to('#navigation', {
        opacity: 0,
        width: 0,
        height: 0,
        zIndex: -1
      })
      document.body.style.position = ''
    },
    onScroll (event) {
      this.change = event.target.scrollingElement.scrollTop >= this.viewport[1] - (this.viewport[1] / 12);
    }
  }
}
</script>
