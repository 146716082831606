const Worker = {
    install(Vue) {
        Vue.prototype.$worker = {
            styleResolve: (value, length) => {
                return `${(value * 100) / length}%`
            }
        }
    }
}

export default (Worker)
