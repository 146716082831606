<template>
  <div id="navigation">
    <div class="menu" :style="`left:${viewport[0]}px; width: ${viewport[0]}px; height: ${viewport[1]}px`">
      <div class="menu-items">
        <router-link data-route class="display-3 font-weight-bold white--text my-auto underline--magical"
            v-for="(item, i) in menu"
            :key="i"
            :to="item.route">{{ item.title }}</router-link>
      </div>
      <v-row class="menu-quote">
        <v-col cols="10" class="pa-lg-12">
          <transition enter-active-class="transition-ease-in-out"
                      leave-active-class="transition-ease-in-out">
            <p class="display-2 font-weight-bold white--text">{{ quote.text | quote }}</p>
          </transition>
          <transition enter-active-class="transition-ease-in-out"
                      leave-active-class="transition-ease-in-out">
            <p class="text-right">
              <small class="title font-weight-bold white--text">{{ quote.author | dash }}</small>
            </p>
          </transition>
        </v-col>
      </v-row>
    </div>
  </div>
</template>

<script>
export default {
  name: "Menu",
  props: {
    viewport: Array
  },
  data () {
    return {
      menu: [
        {
          title: 'Portfolio',
          route: ''
        },
        {
          title: 'About Us',
          route: ''
        },
        {
          title: 'News',
          route: ''
        },
        {
          title: 'Product & Services',
          route: ''
        },
        {
          title: 'Privacy',
          route: ''
        },
        {
          title: 'Impressum',
          route: ''
        }
      ],
      quote: {
        text: null,
        author: null
      },
      interval: null
    }
  },
  mounted() {
    this.getQuote();
    this.fetchEntriesDebounced();
  },
  methods: {
    getQuote() {
      this.$http.get('api/v2/search/quote')
          .then(response => {
            this.quote = response.data.quote;
          })
    },
    fetchEntriesDebounced() {
      clearInterval(this.interval);
      this.interval = setInterval(() => {
        this.getQuote()
      }, 1000 * 10)
    }
  }
}
</script>

<style scoped lang="scss">
  #navigation {
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    right: 0;
    border-bottom-left-radius: 100%;
    & .menu {
      position: absolute;
      top: 0;
      display: grid;
      grid-template-columns: 5% 30% 60% 5%;
      grid-template-rows: 10% 80% 10%;
      & .menu-items {
        display: flex;
        flex-flow: column;
        grid-row: 2 / 3;
        grid-column: 2 / 3;
        & a {
          text-decoration: none;
        }
      }
      & .menu-quote {
        grid-column: 3 / 4;
        grid-row: 2 / 3;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
  .underline--magical {
    background-image: linear-gradient(120deg, #1E2C40 0%, #fff 100%);
    background-repeat: no-repeat;
    background-size: 0 0.8em;
    background-position: 0 88%;
    transition: background-size 0.25s ease-in;
    &:hover {
      background-size: 100% 0.8em;
    }
  }
</style>
